import React from "react";
import {MdQuestionAnswer} from "react-icons/md";

import Layout from "../../components/layout";
import {OrderBreadcrumb as Breadcrumb} from "../../components/breadcrumb";

const pageTitle = "License Agreement";

const Page = () => (
    <Layout pageTitle={pageTitle}
            pageNav={
                <Breadcrumb activeName={pageTitle}>
                    <div className="d-none d-sm-flex align-items-center">
                        <div title="Questions?"><MdQuestionAnswer className="mr-2"/></div>
                        <a href="mailto:order@sqlapi.com?subject=License Agreement Questions">order@sqlapi.com</a>
                    </div>
                </Breadcrumb>}
    >
        <h4>SQLAPI++ Library License Agreement and Warranty</h4>
        <p>
            You should carefully read the following
            terms and conditions before using this software. Your use
            of this software indicates your acceptance of this
            license agreement and warranty.
        </p>

        <h5>License Agreement</h5>
        <p>
            You have the non-exclusive right to use the library. The
            Company retains all title and ownership of the library.
        </p>
        <h5>Evaluation</h5>
        <p>
            The SQLAPI++ library is distributed as
            shareware. You can use the library for evaluation
            purposes without charge for unlimited period. The evaluation version
            has no limits or functional differences from registered version, but
            it will display registration messages occasionally.
        </p>
        <p>
            <span>While in evaluation you </span>
            <span className="text-danger mark">may not</span>
            <span> distribute your applications written with trial version of SQLAPI++ library.</span>
        </p>
        <h5>Registered Versions</h5>
        <p>
            You have to
            purchase SQLAPI++ Personal license for every developer, or one Site
            license for up to 10 developers in your company or
            one Site+ license for unlimited number of developers in your company.
        </p>
        <p>
            <span>You </span>
            <span className="text-success mark">may</span>
            <span> distribute, directly and indirectly, applications written with SQLAPI++ (including SQLAPI++
            run-time) without any additional charge.</span>
        </p>
        <p>
            <span>You </span>
            <span className="text-danger mark">may not</span>
            <span> distribute SQLAPI++ source codes (original or modified).</span>
        </p>

        <h5>Registered version (Personal license)</h5>
        <p>
            One registered copy of SQLAPI++ library may be used by a single
            person who uses it personally on one or more computers.
        </p>
        <p>
            You may
            access the registered version of SQLAPI++ library through a network,
            provided that you have obtained individual licenses for the software
            covering all developers that will access the software through the
            network.
        </p>

        <h5>Registered version (Site license)</h5>
        <p>
            One registered copy of SQLAPI++ may be used by up to 10
            developers in the same company.
        </p>

        <h5>Registered version (Site+ license)</h5>
        <p>
            One registered copy of SQLAPI++ may be used by any number of
            developers in the same company.
        </p>

        <h5>Warranty and limitation of liability</h5>
        <p>
            We warrant that you will receive
            {' '}<span className="text-primary mark">ongoing free</span>{' '}
            email support,
            {' '}<span className="text-primary mark">one year free</span>{' '}
            bugs fixing and
            {' '}<span className="text-primary mark">one year free</span>{' '}
            upgrades to new versions.
        </p>
    </Layout>
);

export default Page;
